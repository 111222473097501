/*** 12. Homepage slider ***/
.sh__homepageSlider {padding-top: 120px;}
.sh__slider-item {
	height: 780px;
	position: relative;	
	@include max_desktop{height: 380px; margin-bottom: 10px;}
}
.sh__homepageSlider .sh__slider-item {margin-left: 5px;}
.sh__slider-item:focus {outline: none;}
.sh__sldier-caption {
	position: absolute;
	left: 50%;
	top: 50%;
	width: fit-content;
	max-width: 80%;
	text-align: center;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity .2s ease-out;
}
.sh__item-details {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	opacity: 0;
	background: rgba(53,50,46,0.68);
	transition: all 0.2s ease-out;
}
.sh__slider-item:hover .sh__sldier-caption {opacity: 1;}
.sh__slider-item:hover .sh__item-details {opacity: 1;}
.sh__sldier-text {
	font-size: 32px;
	line-height: 42px;
	color: #ffffff;
	font-family: "League Spartan",Helvetica,Arial,sans-serif;
}
.sh__slider-container {padding: 0;}

/* slider arrows */
.sh__homepageSlider .slick-slider {
	.slick-prev:before, .slick-next:before {
		color: #000; ; 
	}
	.slick-next { 
		@include min_tablet {right: -3%;}
	}
	.slick-prev { 
		@include min_tablet {left: -3%;}
	}
}

/* slider items gutter & height */
.sh__homepageSlider .slick-slide {margin: 0 5px;}
.sh__homepageSlider .slick-list {margin: 0 -5px;}