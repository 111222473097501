/*------------------------------------------------------------------
[Table of contents]
1. Variables
2. Misc settings
3. General settings
4. Fonts settings
5. Default styles
6. Template grid
7. Totop button
8. Page preloader 
9. Header styles
10. Navigation overlay
11. Footer styles
12. Homepage slider
13. About section 
14. Project completed section
15. Testimonial slider
16. Envisioneer section
17. Projects slider
18. Projects Gallery
19. Contact section
20. Grid gallery
-------------------------------------------------------------------*/

/*** 1. Variables ***/
@import 'others/_vars';

/*** 2. Misc settings ***/
@import 'others/_mixins';

/*** 3. General settings ***/
@import '_general';
@import '_site-header';
@import '_site-footer';

/*** Pages ***/
@import '_homepage';
@import '_about';
@import '_projects';
@import '_contact';
@import '_singlePage';

