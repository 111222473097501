/*** 9. Header styles ***/
/* header navbar */
.sh__headerNavbar {height: 120px; display: table; width: 100%; position: fixed; z-index: 99; background: #fff; transition: height .2s ease-out;}
.sh__navbarContainer {display: table-cell; vertical-align: middle;}

/* sticky header */
.header--is-sticked.sh__headerNavbar{
	height: 60px;
	transition: height .2s ease-out;
}
/* logo container & img */
.sh__logo-container {width: 220px; margin: auto;}
.sh__site-logo {margin: 0;}

/* logo anch */
.sh__logo-anch {
	vertical-align: middle;
	top: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
}

/* hamburger icon */
.sh__hamburgerMenu {
	position: relative;
	right: 6%;
	float: right;
}
.sh__hamburger-trigger {
	width: 15px;
	height: 11px;
	opacity: 1;
	margin-right: 0;
	position: relative;
	display: block;
}
.sh__hamburger-trigger span{
	height: 2px;
	background: #000;
	display: block;
	position: absolute;
	width: 100%;
	border-radius: 2px;
	opacity: 1;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
}
.sh__hamburger-trigger span:first-child {top: 0;}
.sh__hamburger-trigger span:nth-child(2) {top: 9px;}
.sh__hamburger-trigger span:nth-child(3) {top: 18px; display: none;}
.sh__hamburger-trigger.is-active span:nth-child(1) {transform: rotate(135deg);}
.sh__hamburger-trigger.is-active span:nth-child(2){transform: rotate(-135deg);}
.sh__hamburger-trigger.is-active span:nth-child(3){transform: rotate(-135deg);}

/* Menu icon hover state */
.sh__hamburger-trigger:hover span {background: #{$templateColor};}

/*** 10. Navigation overlay ***/
.sh__navOverlay.is-active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0) translateZ(0);
	transition: opacity .6s ease-out, visibility .6s ease-out;
}
.sh__navOverlay.sh__navOverlay--closed {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-100%) translateZ(0);
	transition: opacity .6s ease-out, visibility .6s ease-out;
}
.sh__navOverlay {
	background-color: #ffffff;
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	min-height: 100%;
	width: 100%;
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

/* close button with animations */
.sh__navOverlay-close {
	position: fixed;
	z-index: 2;
	height: 54px;
	width: 54px;
	border-radius: 50%;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	transition: opacity .2s ease-out,-webkit-transform .7s ease-out;
	transition: transform .7s ease-out,opacity .2s ease-out;
	transition: transform .7s ease-out,opacity .2s ease-out,-webkit-transform .7s ease-out;
	opacity: .8;
	right: 30px;
	top: 30px;
}
.sh__navOverlay-close span {
	position: relative;
	display: block;
	z-index: 1;
	width: 100%;
	height: 100%;
	text-indent: 100%;
	white-space: nowrap;
}
.sh__navOverlay-close span:after, .sh__navOverlay-close span:before {
	content: "";
	width: 2px;
	height: 0;
	display: block;
	top: 50%;
	left: 50%;
	position: absolute;
	background: #000;
	transition: height .3s ease-out;
}
.sh__navOverlay.is-active .sh__navOverlay-close span:after, .sh__navOverlay.is-active .sh__navOverlay-close span:before {
	height: 15px;
}
.sh__navOverlay-close span:before {
	transform: translate(-50%,-50%) rotate(45deg);
	transition-delay: .6s;
}
.sh__navOverlay-close span:after {
	transform: translate(-50%,-50%) rotate(-45deg);
	transition-delay: .8s;
}
.sh__navOverlay.is-active .sh__navOverlay-close {transform: rotate(180deg);}
.sh__navOverlay-close svg {
	position: absolute;
	top: 0;
	left: 0;
}
.sh__navOverlay.is-active .sh__navOverlay-close circle {
	stroke-dashoffset: 0;
	stroke-opacity: 1;
}
.sh__navOverlay-close circle {
	transition: stroke-dashoffset .6s ease-out .4s,stroke-width .2s ease-out,stroke-opacity .2s ease-out;
	stroke: #000;
	stroke-width: 1;
}

/* navigation overlay menu with animations */
.sh__navOverlay-inner {
	display: block;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: 60px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
}
.sh__navOverlay--menuWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	flex: 1 1 100%;
	min-height: calc(100% - 80px);
}
.sh__navOverlay--menuWrapper-inner {width: 100%;}
.sh__navOverlay--mainNav {
	letter-spacing: 1px;
	text-align: center;
	display: block;
	position: relative;
	padding: 40px 0;
	width: 100%;
}
.sh__navOverlay--mainNav a {
	color: #111;
	display: inline-block;
	position: relative;
	font-size: 20px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 1em;
	padding: .17em 0;
}
.sh__navOverlay li > a.active {color: #{$templateColor};}
.sh__navOverlay li > a:hover {color: #{$templateColor};}
.sh__navOverlay.is-active .sh__navOverlay--mainNav>li:first-child{transition-delay: .06s;}
.sh__navOverlay.is-active .sh__navOverlay--mainNav>li:nth-child(2){transition-delay: .12s;}
.sh__navOverlay.is-active .sh__navOverlay--mainNav>li:nth-child(3){transition-delay: .18s;}
.sh__navOverlay.is-active .sh__navOverlay--mainNav>li:nth-child(4){transition-delay: .24s;}
.sh__navOverlay.is-active .sh__navOverlay--mainNav>li {
	opacity: 1;
	transform: translateY(0);
	transition: transform .5s ease 0s,opacity .4s ease 0s,-webkit-transform .5s ease 0s;
	position: relative;
	backface-visibility: hidden;
}
.sh__navOverlay.sh__navOverlay--closed .sh__navOverlay--mainNav>li {
	opacity: 0;
	position: relative;
	backface-visibility: hidden;
	transform: translateY(-100%);
	transition: transform .25s ease-in 0s,opacity .5s ease 0s,-webkit-transform .25s ease-in 0s;
}
.sh__navOverlay li {
	padding: 0;
	margin: 0;
	list-style: none;
}
.sh__navOverlay li  {
	display: inline-block;
	backface-visibility: hidden;
	transition: .2s ease-out;
	transition-delay: 0s;
	position: relative;
	width: 100%;
}

/* Navigation overlay sub-items */
.sh__navOverlay li ul li a {
	font-size: 20px;
	letter-spacing: 1px;
	text-transform: initial;
	display: inline-block;
}
.sh__navOverlay li ul li {margin-bottom: 0px;}
.sh__navOverlay li ul {padding-left: 0px;}

/* menu links decoartions */
.sh__navOverlay .sh__navOverlay--mainNav > li:hover a:after,
.sh__navOverlay .sh__navOverlay--mainNav > li a.active:after {width: 100%;}
.sh__navOverlay .sh__navOverlay--mainNav > li a {text-decoration: none;}
.sh__navOverlay .sh__navOverlay--mainNav > li:hover ul li a:after {width: 0%;}
.sh__navOverlay .sh__navOverlay--mainNav > li a:after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: 0;
	height: 1px;
	background-color: hsla(0,0%,7%,.45);
	width: 0;
	transition: width .15s cubic-bezier(.68,0,.265,1),background-color .15s cubic-bezier(.68,0,.265,1);
	transform: translateX(-50%);
}
