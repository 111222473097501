/*** 13. About section ***/
.sh__aboutSection {padding-top: 120px;}

/* caption */
.sh__about-caption {
	background-color: #ffffff;
	padding-top: 100px;
	padding-right: 120px;
	padding-left: 85px;
	width: 50%;
	margin-top: -220px;
	min-height: 220px;
	text-align: left;
	@include max_tablet {
		padding-top: 50px;
		padding-right: 0px;
		padding-left: 0px;
		width: 100%;
	}
	@include tablet_landscape {
		width: 80%;
		padding-top: 60px;
		padding-right: 80px;
		padding-left: 50px;
	}
}
.sh__about-caption h1 {margin-bottom: 25px;}

/*** 14. Project completed section ***/
.sh__projectsSection {
	padding-top: 130px;
	padding-bottom: 120px;
	@include desktop {padding-top: 60px; padding-bottom: 20px;}
	@include max_desktop {padding-top: 60px; padding-bottom: 20px;}
}
.sh__projectsCompleted-title {
	font-size: 32px;
	line-height: 42px;
	color: #d1d1d1;
	margin-bottom: 10px;
	padding-bottom: 35px;
	text-align: center;
	margin-left: 2em;
	margin-right: 2em;
	margin-top: 0px;
	@include ldesktop {margin-left: 0px; margin-right: 0px;}
	@include desktop {margin-left: 0px; margin-right: 0px;}
	@include max_desktop {margin-top: 60px; margin-left:0px; margin-right: 0px;}
	@include mobile {margin-left: 0px; margin-right: 0px;}
}

/* 1st testimony section */
.sh__TestimonySection-first {padding-bottom: 100px;}
.sh__TestimonySection-title {
	font-size: 28px;
	line-height: 48px;
	margin-top: 0;
	text-align: center;
	padding-bottom: 35px;
}
.sh__signature-imageBox {height: 80px;}

/*** 15. Testimonial slider ***/
.sh__TestimonySlider {
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #2d2d2d;
}
.sh__testimonialSubtitle {
	font-size: 14px;
	line-height: 24px;
	margin-top: 0;
	color: #dcdcdc;
}
.sh__slider-textBlock {
	@include max_ldesktop {width: 100%;}
	@include max_tablet {position: relative; left: 0%; transform: none;}
}

/*** 16. Envisioneer section ***/
.sh__envisioneerSection {
	padding-top: 100px;
	padding-bottom: 100px;
}
.sh__envisioneerDescription {
	font-size: 18px;
	line-height: 32px;
	color: #111111;
}
.sh__envisioneer-block {
	@include max_desktop {
		position: relative;
		left: 0%;
		transform: none;
		width: 100%;
		top: 20px;
	}
}

/*** 17. Projects slider ***/
.sh__projects-item {
	height: 440px;
}
.sh__projects-text {
	font-size: 20px;
	font-weight: 400;
	margin: auto 0 25px 0;
	color: #fff;
}
.sh__projects-category {
	font-size: 16px;
	line-height: 26px;
	display: inline-block;
	margin-bottom: auto;
	padding-top: 20px;
	color: #fff;
	position: relative;
}
.sh__projects-category:before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 160%;
	height: 1px;
	transform: translateX(-50%);
	background: #ab9e87;
}
.sh__projectsSlider .slick-prev {left: 0px; z-index: 9; }
.sh__projectsSlider .slick-next {right: 10px; z-index: 9; }