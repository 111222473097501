/*** 4. Fonts settings ***/
@font-face {
	font-family: 'League Spartan';
	src: url('../fonts/leaguespartan/leaguespartan-bold.eot');
	src: url('../fonts/leaguespartan/leaguespartan-bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/leaguespartan/leaguespartan-bold.woff2') format('woff2'),
		 url('../fonts/leaguespartan/leaguespartan-bold.woff') format('woff'),
		 url('../fonts/leaguespartan/leaguespartan-bold.ttf') format('truetype'),
		 url('../fonts/leaguespartan/leaguespartan-bold.svg#league_spartanbold') format('svg');
	font-weight: bold;
	font-style: normal;
}

/*** 5. Default styles ***/

/*** images ***/
/* responsive images */
img {width: 100%; height: auto;}

/* image box */
.sh__imageBox {display: block;}
.sh__imageBox-img {margin: 0 auto; width: auto; height: 100%; display: block; @include desktop {width: 100%; height: auto;}}
.sh__imageBox--left {float: left; padding-right: 40px; @include desktop {padding-right: 0px;}}

/* default template font */
body {font-size: 14px; line-height: 32px; font-family: #{$defaultFont};}

/*** Typography ***/
/* headings */
h1 {
	font-size: 38px;
	line-height: 48px;
	color: #{$templateColor};
}
h2 {color: #{$templateColor};}
h3 {color: #{$templateColor};}
h4 {color: #{$templateColor};}
h5 {color: #{$templateColor};}
h6 {color: #{$templateColor};}

/* paragraph */
p {
	font-size: 14px;
	line-height: 32px;
	margin: 0 0 20px;
}

.sh__subtitle {color: #848484;}

/* lists */
ul {list-style-type: none;}

/* links */
a {color: #{$templateColor};}
a:hover {text-decoration: none; color:#{$templateColor};}
a:link {text-decoration: none;}
a:focus {outline: none;}
a {
	font-size: 12px;
	line-height: 28px;
	color: #0a0a0a;
}

/* colors */
.sh__color--black {color: #111111;}
.sh__color--white {color: #ffffff;}

/*** form element ***/

/* text field */
button, input, optgroup, select, textarea {
	font-family: sans-serif;
}
input[type="password"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
	outline: none;
}
input[type="password"],
input[type="text"],
input[type="file"],
textarea {
	max-width: 100%;
	margin-bottom: 15px;
	border-color: #ddd;
	padding: 18px 10px 10px;
	height: auto;
	background-color: transparent;
	box-shadow: none;
	border-width: 0 0 1px;
	border-style: solid;
	border-radius: 0;
	display: block;
	width: 100%;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-image: none;
	border-bottom: 1px solid #ccc;
	border-color: ease-in-out .15s, box-shadow ease-in-out .15s;
}
input:focus, textarea:focus {
	border-bottom-width: 2px;
	border-color: #a08c69;
}
input[type="text"]:focus+label,
input[type="password"]:focus+label,
input[type="email"]:focus+label,
.sh__input[class~="hasContent"]+label {
	top: -10px;
	font-size: 10px;
	font-weight: 700;
}
input[type="text"]+label,
input[type="password"]+label,
input[type="email"]+label,
textarea+label {
	font-size: 12px;
	position: absolute;
	top: 10px;
	left: 15px;
	cursor: text;
	font-weight: 400;
	transition: all .25s ease;
}
textarea:focus + label {display: none;}
textarea[class~="hasContent"] + label {display: none;}
.sh__form-component {
	margin-bottom: 5px;
	position: relative;
}
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
	text-shadow: none;
	padding: 10px 20px;
	line-height: 1.6;
	box-shadow: none;
	font-size: 13px;
	font-weight: 700;
	border-style: solid;
	color: #fff;
	border-width: 0;
	transition: background-color .15s ease-out;
	background-color: #{$templateColor};
}
.sh__btn--rounded {border-radius: 3px;}
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {
	background-color: #807054;
}
select {
	padding: 10px;
	border-radius: 5px;
}

table, th, tr, td {
	border: 1px solid #000;
}
th, tr, td {padding: 10px;}

input[type="radio"],
input[type="checkbox"] {
	display: inline;
}

/*** 6. Template grid ***/
.sh__gutter--0>[class*=col-] {
	padding-right: 0px;
	padding-left: 0px;
}
.sh__gutter--5>[class*=col-] {
	padding-right: 5px;
	padding-left: 5px;
}
.sh__gutter--5 {
	margin-right: -5px;
	margin-left: -5px;
}
.sh__container-80 {width: 80%;}
.sh__container-90 {width: 90%;}
.sh__container-full {width: 100%;}
.sh__imgContainer-700 {
	height: 700px;
	overflow: hidden;
	@include ldesktop {height: 600px;}
	@include desktop {height: 550px;}
	@include tablet_landscape {height: 440px;}
	@include tablet_portrait {height: 460px;}
	@include mobile {height: 430px;}
}
.sh__imgContainer-700 img {
	@include mobile {
		height: 55%;
		width: auto;  
		overflow: hidden;
	}
}
.sh__imgContainer-400 {
	min-height: 400px;
	@include ldesktop {min-height: 300px;}
	@include desktop {min-height: 250px;}
	@include tablet_landscape {min-height: 440px;}
	@include tablet_portrait {min-height: 460px;}
	@include mobile {min-height: 360px;}
}

/* text block */
.sh__textBlock {position: relative;}
.sh__textBlock-h700 {
	min-height: 700px;
	@include ldesktop {min-height: 550px;}
	@include desktop {min-height: 450px;}
	@include max_desktop {min-height: 450px;}   
}
.sh__text--center {text-align: center;}
.sh__textBlock--w40 {width: 40%; @include max_tablet {width: 90%;}}
.sh__textBlock--w50 {width: 50%; @include max_tablet {width: 90%;}}
.sh__textBlock--w60 {width: 60%; @include max_tablet {width: 90%;}}
.sh__textBlock--center {position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);}

/* details block */
.sh__details-block {
	padding-top: 0px;
	padding-bottom: 30px;
}

/* separator */
.sh__separator {
	border-top-width: 1px;
	border-top-color: #eaeaea;
	border-top-style: solid;
	margin-top: 25px;
	margin-bottom: 50px;
}

/* padding & margin */
.sh__marginBottom-40 {margin-bottom: 40px;}
.sh__paddingR--80 {padding-right: 80px;}

/*** 7. Totop button ***/
.totop {
	height: 9px;
	opacity: 0;
	position: fixed;
	right: -60px;
	width: 49px;
	z-index: 999;
	display: block;
	top: 85%;
	background-repeat: no-repeat;
	background-position: center 15px;
	background-color: #404040;
	font-size: 9px;
	font-weight: 900;
	color: #fff;
	text-align: center;
	line-height: 1;
	border-radius: 2px;
	padding: 28px 0 21px 0;
	transition: all 0.2s ease-out;
}
.totop-vissible {
	right: 10px;
	opacity: 0.7;
}
.totop:before {
	position: absolute;
	content: "\e080";
	top: 10px;
	left: 50%;
	margin-left: -6px;
	font-size: 11px;
	display: inline-block;
	font-family: "Glyphicons Halflings";
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	transform: rotate(-90deg);
}
.totop:hover {color: #fff;}
.totop:focus {color: #fff;}

/*** 8. Page preloader ***/

.sh__pageLoading {   
	color: #ffffff;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none; 
	background-color: #fff;
}

/* prelaoder animation */
@keyframes preloader-breath-img { from { opacity: .2; } to { opacity: 1; } }

.sh__pageLoading.sh__preloaderBreath .sh__pageLoading-inner {    
	animation-name: preloader-breath-img; 
	animation-duration: 1800ms;  
	animation-delay: 200ms;  
	animation-timing-function: cubic-bezier(0.73, 0.005, 0.42, 1.005);      
	animation-iteration-count: infinite;      
	animation-direction: alternate; 
}

