/* layout */
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;
$slarge-desktop-width: 1680px;
$glyph: 'Glyphicons Halflings';
$desktop-height: 1050px;

/* colors */
$templateColor: #a08c69;
$defaultFont: "League Spartan",Helvetica,Arial,sans-serif;