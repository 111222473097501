/* google map */
.sh__mapSection {padding-top: 120px;}
#contactMap {width: 100%; height: 700px;}

/* sldier */
.sh__contactSlider .sh__slider-item{max-height: 390px; @include max_tablet{height: 100%;}}

/*** 19. Contact section ***/
.sh__contactSection {
	padding-top: 100px;
	padding-bottom: 100px;
}
.sh__contactCol {@include max_tablet {padding-left: 0px;}}

/* contact us details */
.sh__details-title {
	font-size: 14px;
		line-height: 24px;
		color: #d1d1d1;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 10px;
}
.sh__details-subtitle {
	font-size: 16px;
		line-height: 26px;
		color: #0a0a0a;
}
.sh__details-link {font-size: 16px; color: #{$templateColor};}

/* contact form */
.js-disable-action {pointer-events: none;}
.js-cf-message {
	display: none;
	border-left: solid 3px transparent;
	padding-left: 5px;
}
.js-response-success {border-left-color: #9bf442;}
.js-response-error {border-left-color: #dd0000;}
