/*** 11. Footer styles ***/
.sh__footer-container {
	padding-top: 60px;
	padding-bottom: 60px;
	width: 80%;
	@include tablet_portrait{padding-top: 15px;}
}
.sh__footer-list li{
	display: inline-block;
	margin: 0 10px;
	position: relative;
	@include mobile{display: block;}
}
.sh__footer-list {
	padding-top: 15px;
	margin-bottom: 20px;
	padding-left: 0px;
}

/* footer social icons */
.sh__socialIcons {text-align: right;}
.sh__socialIcon {
	color: #bbbbbb;
	border-radius: 3px;
	font-size: 14px;
	padding: 20px;
}
.sh__socialIcons-item {display: inline-block;}
.sh__copyright {text-align: center; color: #808080; font-size: 14px;}

.sh__socialLink:hover span{color: #{$templateColor};}