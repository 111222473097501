.sh__descriptionSection {
	padding-top: 130px;
	padding-bottom: 120px;
	@include desktop {padding-top: 60px; padding-bottom: 20px;}
	@include max_desktop {padding-top: 60px; padding-bottom: 20px;}
}

/* Project photos */
.sh__projectPhotos {
	padding-top: 120px;
	padding-bottom: 90px;
	background-color: #f4f4f4;
}

/*** 20. Grid gallery ***/
.sh__gridGallery {position: relative;}
/* grid items */
.sh__gridItem {
	float: left;
	position: absolute;
	left: 0px;
	top: 0px;
}
.sh__gridGallery[data-columns="2"] .sh__gridItem {width: 50%; @include mobile{width: 100%;}}
.sh__gridGallery[data-columns="3"] .sh__gridItem {width: 33.33%; @include mobile{width: 100%;}}
.sh__gridGallery[data-columns="4"] .sh__gridItem {width: 25%; @include mobile{width: 100%;}}
.sh__gridItem-link {
	width: 100%;
	height: 0;
	display: block;
	padding: 0;
	margin-bottom: 0;
	position: relative;
}
.sh__gridImg-wrapper {
	top: 15px;
	right: 15px;
	left: 15px;
	bottom: 15px;
	width: calc(100% - 30px);
	height: calc(100% - 30px);
	display: block;
	position: absolute;
	overflow: hidden;
}
.sh__gridImg {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
	overflow: hidden;
}
.sh__gridItem--h1 {
	padding-bottom: 75%;
}
.sh__gridItem--h2 {
	padding-bottom: 150%; @include mobile{padding-bottom: 75%;}
}

/* grid search icon */
.sh__circled-icon {
	width: 56px;
	height: 56px;
	line-height: 56px;
	display: inline-block;
	border-radius: 50%;
	box-sizing: content-box;
	text-align: center;
	border: 2px solid currentColor;
}
.sh__circled-icon:before {
	line-height: 56px;
	display: block;
	vertical-align: middle;
}
.sh__icon {
	opacity: 0;
	position: absolute;
	top: 50%;
	margin-top: 15px;
	left: 50%;
	transform: translate(-50%,-50%);
	transition: all .25s ease-in-out;
	border-color: #fff;
	color: #fff;
}

/* grid zooming animation */
.sh__gridItem-link:hover .sh__icon{
	display: block;
	opacity: 1;
	margin-top: 0;
}
.sh__gridItem-link.sh__zoom--on .sh__gridImg {
	transition-duration: 2s;
	transition: transform 0.7s linear,-webkit-transform 0.7s linear;
}
.sh__gridItem-link.sh__zoom--on:hover .sh__gridImg{
	transform: scale(1.1);	
}