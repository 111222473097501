/*** Projects subheader ***/
.sh__projectsSubheader {
	padding-top: 180px; 
	padding-bottom: 0px; 
	position: relative;
}
.sh__projectsTitle {margin-bottom: 25px;}
.sh__projectsBlock {height: 200px; @include tablet_landscape {height: 250px;} @include tablet_portrait {height: 300px;} @include mobile {height: 350px;}}

/*** 18. Projects Gallery ***/
.sh__projectsGallery {padding-top: 60px;}
.sh__gallery-toolbar {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	display: table;
	padding: 0;
}
.sh__gallery-toolbar li {float: left; margin-bottom: 10px;}
.sh__gallery-toolbar li a {
	background: #fff;
	padding: 15px;
	line-height: 1;
	font-size: 10px;
	text-transform: uppercase;
	display: inline-block;
	margin-right: 0;
	transition: all .2s ease-in-out;
	pointer-events: none;
	cursor: pointer;
	color: #{$templateColor};
}
.sh__gallery-toolbar li.active a{
	color: #fff!important;
	background-color: #{$templateColor};
}

.sh__gallery-items {padding-left: 0px; @include mobile{margin-left: -40px;}}
.sh__gallery-items[data-columns="2"] .sh__gallery-item {width: 50%;}
.sh__gallery-items[data-columns="3"] .sh__gallery-item {width: 33.33%;}
.sh__gallery-items[data-columns="4"] .sh__gallery-item {width: 25%;}
.sh__gallery-items[data-columns] .sh__gallery-item{@include mobile{width: 100%;}}
.sh__gallery-item  {float: left; padding: 5px; position: relative;}
.sh__item-caption {
	position: absolute;
	left: 50%;
	top: 50%;
	width: fit-content;
	max-width: 80%;
	text-align: center;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity .2s ease-out;
}
.sh__gallery-item:hover .sh__item-details {opacity: 1;}
.sh__gallery-item:hover .sh__item-caption {opacity: 1;}
.sh__gallery-item .sh__item-details {left:5px; right: 5px; top: 5px; bottom: 5px; background: rgba(53, 50, 46, 0.55);}